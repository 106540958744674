import { Namespace } from '@prisma/client';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { sendToRollbar } from '../utils/rollbar';

export interface NamespaceService {
    getNamespace(clientId: string): Promise<BaseResponse<Namespace>>;
}

export class NamespaceServiceImp implements NamespaceService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        this.httpRequest = httpRequest;
    }

    async getNamespace(clientId: string): Promise<BaseResponse<Namespace>> {
        try {
            const response = await this.httpRequest.get<Namespace>({
                url: generateUrl(`api/client/namespace`, { clientId })
            });

            if (response.error) {
                console.error('Error fetching data: ', response.error);
                sendToRollbar('[Namespace-service] getNamespace error:', {
                    custom: { error: response.error }
                });
            }

            return response;
        } catch (error) {
            console.error('Error fetching data: ', error);
            sendToRollbar('[Namespace-service] getNamespace error: ', {
                custom: { error }
            });
            return { body: {} as Namespace, error: error, sessionExpired: false };
        }
    }
}
