import { Client, ClientPlan, User } from '@prisma/client';

import { PaginatedData } from '@/types/paginatedData';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { sendToRollbar } from '../utils/rollbar';
import { BaseService } from './base-service';
import { ClientUser } from '../pages/clients/client-form/utils/client-form-data-manager';

export type ClientWithEmails = Client & { users: { email: string }[] };

export interface ClientService {
    getClient(id: string): Promise<BaseResponse<Client>>;
    getAllClients(): Promise<BaseResponse<PaginatedData<Client>>>;
    getClients(criteria?: {
        page?: number;
        pageSize?: number;
        search?: string;
        id?: string;
        withEmails?: boolean;
        organizationSize?: string;
    }): Promise<BaseResponse<PaginatedData<Client | ClientWithEmails>>>;
    postClient(
        name: string,
        timeOffset: number,
        whatsappId?: number,
        facebookPageId?: string,
        clientLogo?: Blob,
        planId?: string
    ): Promise<BaseResponse<Client>>;
    putClient(
        id: string,
        name: string,
        timeOffset: number,
        whatsappId?: number,
        facebookPageId?: string,
        clientLogo?: Blob,
        planId?: string
    ): Promise<BaseResponse<Client>>;
    deleteClient(clientId: string): Promise<BaseResponse<void>>;

    getPlan(clientId: string): Promise<BaseResponse<ClientPlan>>;
    getClientUsers(clientId: string): Promise<BaseResponse<{ users: User[] }>>;
    postClientUser(clientId: string, user: ClientUser): Promise<BaseResponse<User>>;
}

export class ClientServiceImp extends BaseService implements ClientService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Client-Service');
        this.httpRequest = httpRequest;
    }

    async getClient(id: string): Promise<BaseResponse<Client>> {
        try {
            const response = await this.httpRequest.get<Client>({
                url: generateUrl('api/client/' + id)
            });
            if (response.error) {
                console.error(response.error);
                sendToRollbar('[Client-service] Get client Error: ', {
                    custom: { error: response.error }
                });
            }

            return response;
        } catch (error) {
            console.error('Error fetching data: ', error);
            sendToRollbar('[Client-service] Get client Error: ', {
                custom: { error }
            });
            return {
                body: {} as Client,
                error: error,
                sessionExpired: false
            };
        }
    }

    async getClients(criteria?: {
        page?: number;
        pageSize?: number;
        search?: string;
        id?: string;
        withEmails?: boolean;
        organizationSize?: string;
    }): Promise<BaseResponse<PaginatedData<Client | ClientWithEmails>>> {
        try {
            const response = await this.httpRequest.get<PaginatedData<Client | ClientWithEmails>>({
                url: generateUrl('api/client', criteria)
            });
            if (response.error) {
                console.error(response.error);
                sendToRollbar('[Client-service] Get clients Error: ', {
                    custom: { error: response.error }
                });
            }
            return response;
        } catch (error) {
            console.error('Error fetching data: ', error);
            sendToRollbar('[Client-service] Get clients Error: ', {
                custom: { error, criteria }
            });
            return {
                body: {} as PaginatedData<Client>,
                error: error,
                sessionExpired: false
            };
        }
    }

    async getAllClients(): Promise<BaseResponse<PaginatedData<Client>>> {
        return this.getClients({ pageSize: Number.MAX_SAFE_INTEGER });
    }

    async postClient(
        name: string,
        timeOffset: number,
        whatsappId?: number,
        facebookPageId?: string,
        clientLogo?: Blob | null,
        planId?: string
    ): Promise<BaseResponse<Client>> {
        const form = new FormData();

        form.append('name', name);
        form.append('timeOffset', timeOffset.toString());

        if (planId) form.append('planId', planId);
        if (whatsappId) form.append('whatsappId', whatsappId.toString());
        if (facebookPageId) form.append('facebookPageId', facebookPageId);
        if (clientLogo !== undefined) form.append('clientLogo', clientLogo || 'null');

        try {
            // TODO: Use FetchHttpRequest service.
            // For some reason FetchHttpRequest incorrectly assigns 'Content-Type' header, not allowing to correctly create the client.
            const response = await fetch('/api/client', {
                method: 'POST',
                body: form
            });

            const data = await response.json();

            if (!response.ok) throw new Error(data.message);

            return { body: data as Client, sessionExpired: false };
        } catch (error) {
            console.error('Error creating client: ', error);
            sendToRollbar('[Client-service] Post client error: ', {
                custom: { error }
            });
            return { body: {} as Client, error: error, sessionExpired: false };
        }
    }

    async putClient(
        id: string,
        name: string,
        timeOffset: number,
        whatsappId?: number,
        facebookPageId?: string,
        clientLogo?: Blob | null,
        planId?: string
    ): Promise<BaseResponse<Client>> {
        const form = new FormData();

        form.append('name', name);
        form.append('timeOffset', timeOffset.toString());

        if (planId) form.append('planId', planId);
        if (whatsappId) form.append('whatsappId', whatsappId.toString());
        if (facebookPageId) form.append('facebookPageId', facebookPageId);
        if (clientLogo !== undefined) form.append('clientLogo', clientLogo || 'null');

        try {
            // TODO: Use FetchHttpRequest service.
            // For some reason FetchHttpRequest incorrectly assigns 'Content-Type' header, not allowing to correctly edit the client.
            const response = await fetch(`/api/client/${id}`, {
                method: 'PUT',
                body: form
            });

            const data = await response.json();

            if (!response.ok) throw new Error(data.message);

            return { body: data as Client, sessionExpired: false };
        } catch (error) {
            console.error('Error creating client: ', error);
            sendToRollbar('Error creating client: ', { custom: { error } });
            return { body: {} as Client, error: error, sessionExpired: false };
        }
    }

    deleteClient(clientId: string): Promise<BaseResponse<void>> {
        return this.tryRequest<void>(() =>
            this.httpRequest.delete<void>({
                url: generateUrl(`api/client/${clientId}`)
            })
        );
    }

    //maybe we could return the ClientPlanWrapper instead of ClientPlan
    getPlan(clientId: string): Promise<BaseResponse<ClientPlan>> {
        const request = () =>
            this.httpRequest.get<ClientPlan>({
                url: generateUrl(`api/client/${clientId}/plan`)
            });

        return this.tryRequest<ClientPlan>(request);
    }

    getClientUsers(clientId: string): Promise<BaseResponse<{ users: User[] }>> {
        return this.tryRequest<{ users: User[] }>(() =>
            this.httpRequest.get<{ users: User[] }>({
                url: generateUrl(`api/client/${clientId}/users`)
            })
        );
    }

    postClientUser(clientId: string, user: ClientUser): Promise<BaseResponse<User>> {
        return this.tryRequest<User>(() =>
            this.httpRequest.post<User>({
                url: generateUrl(`api/client/${clientId}/users`),
                body: { ...user, key: undefined }
            })
        );
    }
}
