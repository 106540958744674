import React, { useState } from 'react';

const FloatingChatButton = () => {
    const [chatActive, setChatActive] = useState<boolean>(false);
    const [isIframeLoaded, setIsIframeLoaded] = useState<boolean>(false);

    const ChatLoadingSkeleton = () => {
        return (
            <div className="flex flex-col h-full w-full rounded-2xl overflow-hidden">
                <div className="flex z-10 md:h-20 pt-2 pb-2 pl-4 pr-4 flex-row gap-4 items-center w-full bg-white">
                    <div className="relative h-10 w-10 rounded-full skeleton"></div>
                    <div className="flex overflow-hidden flex-col justify-center gap-1">
                        <div className="w-24 h-5 rounded-[5px] skeleton"></div>
                        <div className="w-12 h-4 rounded-[5px] skeleton"></div>
                    </div>
                </div>
                <div className="flex flex-row overflow-hidden w-full bg-indigo-100 p-5 gap-2 flex-1">
                    <div className="rounded-full h-10 w-10 skeleton"></div>
                    <div className="h-20 w-40 rounded-[26px] rounded-bl-[2px] skeleton"></div>
                </div>
                <div className="flex justify-evenly items-center h-[51px] w-full gap-[5px] bg-white">
                    <div className="h-[35px] w-[70%] rounded-[2rem] skeleton"></div>
                    <div className="h-10 w-10 rounded-[10px] skeleton"></div>
                </div>
            </div>
        );
    };

    return (
        <>
            {chatActive && (
                <div className="fixed z-50 rounded-2xl overflow-hidden w-[300px] h-[500px] xl:w-[400px] xl:h-[600px] bg-white bottom-[17vh] right-0 mx-[calc((100vw-300px)/2)] sm:mx-0 sm:bottom-28 sm:right-8 shadow-xl">
                    {!isIframeLoaded && <ChatLoadingSkeleton />}
                    <iframe
                        src="https://delfi.euda.com.ar/webchat?client=Soporte"
                        style={{
                            display: isIframeLoaded ? 'block' : 'none',
                            width: '100%',
                            height: '100%'
                        }}
                        onLoad={() => setIsIframeLoaded(true)}
                    />
                </div>
            )}
            <div className="btn-chatbot-container">
                <p className="chatbot-label">Hola, soy Ceci ¿Tenés alguna duda?</p>
                <button
                    onClick={() => {
                        setChatActive(!chatActive);
                        setIsIframeLoaded(false);
                    }}
                    className="btn-chatbot"
                ></button>
            </div>
        </>
    );
};

export default FloatingChatButton;
