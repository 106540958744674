import { MessageCount } from '@/services/model/analyticsService';
import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { PaginatedData } from '@/types/paginatedData';
import { sendToRollbar } from '../utils/rollbar';

export interface AnalyticsService {
    countMessages(criteria: {
        page: number;
        pageSize: number;
        option: string;
        clientId?: string;
        from?: number;
        to?: number;
    }): Promise<BaseResponse<PaginatedData<MessageCount>>>;
}

export class AnalyticsServiceImp implements AnalyticsService {
    constructor(private httpRequest: HttpRequest) {}

    async countMessages(criteria: {
        page: number;
        pageSize: number;
        option: string;
        clientId?: string;
        from?: number;
        to?: number;
    }): Promise<BaseResponse<PaginatedData<MessageCount>>> {
        try {
            const response = await this.httpRequest.get<PaginatedData<MessageCount>>({
                url: generateUrl('api/analytics', criteria)
            });
            if (response.error) {
                console.error(response.error);
                sendToRollbar(response.error, { custom: { criteria } });
            }
            return response;
        } catch (error) {
            console.error('Error fetching data: ', error);
            sendToRollbar('[Analytics] countMessages error: ', {
                custom: { error, criteria }
            });

            return {
                body: {} as PaginatedData<MessageCount>,
                error: error,
                sessionExpired: false
            };
        }
    }
}
