import { SuggestedQuestion } from '@prisma/client';

import { BaseResponse, HttpRequest } from './fetch-methods';
import generateUrl from './utils/url-generator';
import { SuggestedQuestionFormData } from '../pages/clients/suggestions-form/utils/suggested-questions-data-manager';
import { BaseService } from './base-service';

export interface SuggestedQuestionsService {
    getSuggestedQuestions(clientId: string): Promise<BaseResponse<SuggestedQuestion[]>>;
    updateSuggestedQuestions(
        clientId: string,
        toCreate: SuggestedQuestionFormData[],
        toUpdate: (SuggestedQuestionFormData & { id: string })[],
        toDelete: string[]
    ): Promise<BaseResponse<SuggestedQuestion[]>>;
}

export class SuggestedQuesionsServiceImp extends BaseService implements SuggestedQuestionsService {
    private httpRequest: HttpRequest;

    constructor(httpRequest: HttpRequest) {
        super('Suggested-Question-Service');
        this.httpRequest = httpRequest;
    }

    async getSuggestedQuestions(clientId: string): Promise<BaseResponse<SuggestedQuestion[]>> {
        const request = () =>
            this.httpRequest.get<SuggestedQuestion[]>({
                url: generateUrl('api/client/suggested-questions', { clientId })
            });
        return this.tryRequest<SuggestedQuestion[]>(request);
    }

    async updateSuggestedQuestions(
        clientId: string,
        toCreate: SuggestedQuestionFormData[],
        toUpdate: (SuggestedQuestionFormData & { id: string })[],
        toDelete: string[]
    ): Promise<BaseResponse<SuggestedQuestion[]>> {
        const request = () =>
            this.httpRequest.post<SuggestedQuestion[]>({
                url: generateUrl(`api/client/${clientId}/suggested-questions`),
                body: {
                    create: toCreate.map((question) => this.sanitizeQuestion(question, false)),
                    update: toUpdate.map((question) => this.sanitizeQuestion(question, true)),
                    delete: toDelete
                }
            });
        return this.tryRequest<SuggestedQuestion[]>(request);
    }

    private sanitizeQuestion(
        question: SuggestedQuestionFormData,
        withId: boolean
    ): Omit<SuggestedQuestionFormData, 'key'> {
        return {
            id: withId ? question.id : undefined,
            text: question.text
        };
    }
}
