const generateUrl = (
    path: string,
    criteria?: Object,
    baseUrl: string | undefined = process.env.BASE_URL
): string => {
    if (baseUrl === 'undefined' || baseUrl === undefined) {
        throw new Error('missing BASE_URL');
    }
    let url = baseUrl + path;
    if (criteria && Object.keys(criteria).length > 0) {
        let aleadyHasCriteria = false;
        const criteriaArr = Object.entries(criteria);
        let index = 0;
        for (const [key, value] of criteriaArr) {
            if (!value) {
                index++;
                continue;
            }
            if (!aleadyHasCriteria) {
                url = url + '?';
                aleadyHasCriteria = true;
            }
            url = url + key + '=' + value;
            index++;
            if (index < criteriaArr.length) {
                url = url + '&';
            }
        }
    }

    return url.toString();
};

export default generateUrl;
